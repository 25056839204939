import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Modal, Row, Col, Accordion, Card } from "react-bootstrap"
import SecondInstagramSlider from "../components/second-instagram-slider"
import Reviews from "../components/reviews"
import FlavorSquare from "../components/flavor-square"
import { Component } from "react"
import { Helmet }  from 'react-helmet'
import CustomFaqs from "../components/custom-faqs"

class FlavorsFaq extends Component {
  state = {
    popup: false,
  }

  togglePopup = () => {
    this.setState({
      popup: !this.state.popup,
    })
  }

  render() {
    return (
      <div>
        <Card>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              See the cake flavors{" "}
              <span onClick={this.togglePopup} className="clickable-text" role="button" onKeyDown={this.togglePopup} tabIndex={0}>
                by clicking here
              </span>
            </Card.Body>
          </Accordion.Collapse>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            Cake Flavors
          </Accordion.Toggle>
        </Card>
        <Modal show={this.state.popup} onHide={this.togglePopup} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Cake Flavours</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FlavorSquare />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const WhyDeliciaeTemplate = ({ about }) => (
  <Layout>
    <Helmet>
      <html lang="en" />
      <title>Deliciae by Bunty Mahajan - Why Our Cakes Take The Cake</title>
      <meta
        property="og:description"
        content="Take a look at the testimonials to know why you should choose Deliciae. Also, check out our FAQs to clear any doubts that you may have. "
      />
      <meta
        name="description"
        content="Take a look at the testimonials to know why you should choose Deliciae. Also, check out our FAQs to clear any doubts that you may have. "
      />
    </Helmet>
    <div className={"light-bg why-deliciae"}>
      <div className={"container"}>
        <Row>
          <Col lg="12">
            <h1 className={"text-center my-5"}>Why Deliciae</h1>
            <h2 id="hall-of-fame" className={"text-center my-5"}>
              Hall of Fame
            </h2>
            <SecondInstagramSlider />
            <h2 id="testimonials" className={"text-center my-5"}>
              Testimonials
            </h2>
            <div className="testimonials-holder">
              <Reviews />
            </div>
            <h2 className={"text-center my-5"}>Custom Cakes &amp; Delivery</h2>
            <div className="text-center montserrat">
              Hassle-free ordering process through WhatsApp
            </div>
            <Row className={"text-center my-5 steps"}>
              <Col lg="4">
                <div className="custom-step">01</div>
                <strong>Select</strong>
                <p>Select your design.</p>
              </Col>
              <Col lg="4">
                <div className="custom-step">02</div>
                <strong>Payment</strong>
                <p>Make Payment.</p>
              </Col>
              <Col lg="4">
                <div className="custom-step">03</div>
                <strong>Delivery</strong>
                <p>
                  Have the cake delivered to your desired address at your
                  desired time.
                </p>
              </Col>
            </Row>
            <h2 id="faqs" className={"text-center my-5"}>
              FAQs
            </h2>
            <h4  className={"text-center"}>Ordering</h4>
            <Accordion defaultActiveKey="0" className="faq-accordion">
              <CustomFaqs heading={"Ordering"} />
            </Accordion>
            <h4 className={"text-center"}>Deliveries</h4>
            <Accordion defaultActiveKey="0" className="faq-accordion">
              <CustomFaqs heading={"Deliveries"} />
            </Accordion>
            <h4  className={"text-center"}>Products</h4>
            <Accordion defaultActiveKey="0" className="faq-accordion">
              <CustomFaqs heading={"Products"} />
            </Accordion>
            <h2 className={"text-center my-5"}>
              Vikas Khanna on Deliciae Patisserie
            </h2>
            <iframe
              title="Vikas Khanna on Deliciae Patisserie"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/LhVgVJhSsmA"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
              className={"my-5"}
            ></iframe>
          </Col>
        </Row>
      </div>
    </div>
  </Layout>
)

WhyDeliciaeTemplate.propTypes = {
  about: PropTypes.string,
}

const WhyDeliciae = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return <WhyDeliciaeTemplate about={frontmatter.about} />
}

WhyDeliciae.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default WhyDeliciae
export const query = graphql`
  query WhyDeliciaeTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
      frontmatter {
        about
      }
    }
  }
`
