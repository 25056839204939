import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Accordion, Card } from "react-bootstrap"
import MarkdownContent from "./markdown"

const CustomFaqs = ({ heading }) => (
  <StaticQuery
    query={graphql`
      query CustomFaqs {
        markdownRemark(frontmatter: { templateKey: { eq: "whydeliciae" } }) {
          frontmatter {
            faqs {
              heading
              question
              answer
            }
          }
        }
      }
    `}
    render={(data) => {
      const cards = []
      data.markdownRemark.frontmatter.faqs.forEach((faq, i) => {
        if (faq.heading === heading) {
          cards.push(
            <Card>
              <Accordion.Collapse eventKey={i+""}>
                <Card.Body><MarkdownContent content={faq.answer} /></Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card.Header} eventKey={i+""}>
                {faq.question}
              </Accordion.Toggle>
            </Card>
          )
        }
      })
      return cards
    }}
  />
)

export default CustomFaqs
