import React from "react"
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

SwiperCore.use([Navigation, Pagination]);


const params = {
  slidesPerView: 3,
  spaceBetween: 110,
  pagination: {
    type: "bullets",
  },
  navigation: {
  },

  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 3,
    },
  },
}

const Reviews = () => (
  <StaticQuery
    query={graphql`
      query Reviews {
        allFile(
          filter: { absolutePath: { regex: "/reviews/" } }
          sort: { order: ASC, fields: base }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const photos = data.allFile.edges
      const images = []

      photos.forEach((photo, i) => {
        images.push(<SwiperSlide><Img fluid={photo.node.childImageSharp.fluid} key={i} /></SwiperSlide>)
      })

      return <Swiper {...params}>{images}</Swiper>
    }}
  />
)

export default Reviews
